import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import InfiniteCarousel from "react-leaf-carousel";
import DatePicker from "react-datepicker";
import GoogleMapReact from "google-map-react";
import moment from "moment";
import queryString from "query-string";
import "react-datepicker/dist/react-datepicker.css";

//utils
import { dateFromString, dateToString } from "../utils/dateUtils";
import mediaQuery from "../utils/mediaQuery";
import fetcher from "../utils/fetcher";

//context
import { useBooking } from "../context/BookingContext";

const Wrapper = styled.div`
    margin-top: 65px;
    margin-bottom: 15px;
`;

const Header = styled.div`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://assets.stay-u-nique.com/website/headers/header${Math.ceil(Math.random() * 3)}.JPG");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
`;

const ApartmentName = styled.h5`
    text-overflow: ellipsis;
    overflow: hidden;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    &:hover {
        text-decoration: none;
    }
`;

const ResultsCol = styled(Col)`
    margin-top: -20px;
`;

const MapColumn = styled(Col)`
    display: block;
    ${mediaQuery.down.lg`
        display: none
    `}
    z-index: 1000;
    height: calc(100vh - 200px) !important;
    position: fixed !important;
    top: 200px;
    right: 0;
    & > * {
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    }
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: inherit;
    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
        text-decoration: none;
    }
`;

const StyledRow = styled(Row)`
    padding-top: 15px;
    padding-bottom: 15px;
    @media (min-width: 768px) {
        padding: 15px 15px 15px 0;
        margin: 15px;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        border-radius: 5px;
        transition-duration: 0.2s;
        &:hover {
            box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
            transition-duration: 0.3s;
        }
    }
`;

const Searcher = styled(Container)`
    background-color: white;
    @media (max-width: 768px) {
        margin-bottom: -300px;
    }
    @media (min-width: 768px) {
        position: fixed;
        margin-top: 55px;
        padding-top: 15px;
        top: 0;
        right: 0;
        left: 0;
        z-index: 500;
    }
`;

const Results = styled(Container)`
    margin-top: 205px;
    @media (max-width: 768px) {
        margin-top: 350px;
    }
`;

const Overlay = styled.div`
    background: #ffffffe6;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 5000;
    top: 0;
    left: 0;
    float: left;
    text-align: center;
    padding-top: 42.5vh;
    ${mediaQuery.down.md`
        padding-top: 40vh;
    `}
`;

const TotalPrice = styled.span`
    font-size: 1.5rem;
`;

const StartingPrice = styled.span`
    font-size: 1.2rem;
`;

const BookRow = styled(Row)`
    ${mediaQuery.up.md`
        bottom: 0;
        right: 5px;
        position: absolute;
    `}
`;

const ViewButton = styled(Button)`
    ${mediaQuery.up.sm`
        display: none;
    `}

    ${mediaQuery.down.md`
        margin-top: 10px;
        width: 100%;
    `}
`;

const Error = styled.div`
    margin: 75px 15px;
`;

const DateInput = React.forwardRef((props: any, ref: any) => <Form.Control ref={ref} size="lg" type="text" onClick={props.onClick} placeholder={props.label} value={props.value} readOnly />);

const Map = ({ property, active, handleClick }: any) => {
    return <FontAwesomeIcon icon="home" color={property === active ? "#53c3e6" : "#4e5e9e"} size="3x" onClick={() => handleClick(property)} />;
};

function renderStars(amount: number) {
    var stars = [];
    for (var i = 0; i < Math.floor(amount); i++) {
        stars.push(<FontAwesomeIcon color="#fbbc04" icon="star" key={i} />);
    }
    return stars;
}

const ResultsList = () => {
    const { t } = useTranslation();

    const { currentSearch, updateSearch } = useBooking();

    const [isLoading, setIsLoading] = useState(true);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [error, setError] = useState(false);
    const [activeProperty, setActiveProperty] = useState<string | null>("");
    const [results, setResults] = useState([]);
    const [refs, setRefs] = useState<any>({});
    const [cities, setCities] = useState([]);
    const [regions, setRegions] = useState([]);

    const [from, setFrom] = useState(currentSearch.from);
    const [to, setTo] = useState(currentSearch.to);
    const [guests, setNumGuests] = useState(currentSearch.guests);
    const [selectedCity, setSelectedCity] = useState(currentSearch.selectedCity);
    const [selectedRegion, setSelectedRegion] = useState(currentSearch.selectedRegion);

    useEffect(() => {
        async function fetchData() {
            try {
                const properties = await fetcher(`booking/results?${queryString.stringify(currentSearch)}`);
                setResults(properties);

                const regionsList = await fetcher(`booking/regions`);
                setRegions(regionsList.data);

                const citiesList = await fetcher(`booking/cities?selectedRegion=${selectedRegion}`);
                setCities(citiesList.data);
            } catch (e) {
                console.log(e);
                setError(true);
            }

            setIsInitialLoad(false);
            setIsLoading(false);
        }

        fetchData();
    }, [currentSearch, selectedRegion]);

    useEffect(() => {
        if (results && results.length > 0) {
            setRefs(
                results.reduce((acc: any, value: any) => {
                    acc[value.propertyId] = React.createRef();
                    return acc;
                }, {})
            );
        }
    }, [results]);

    const refetchProperties = () => {
        updateSearch({ from, to, guests, selectedRegion, selectedCity });
    };

    const handleClick = (id: any) => {
        setActiveProperty(id);

        if (refs[id]) {
            refs[id].current.scrollIntoView({
                behavior: "smooth",
                block: "center"
            });
        }
    };

    if (error) {
        return (
            <Error>
                <Alert variant="danger">
                    <Alert.Heading>{t("misc.error")}</Alert.Heading>
                    <p>{t("misc.serverError")}</p>
                </Alert>
            </Error>
        );
    }

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Stay U-nique | {t("results.title")}</title>
                <meta name="description" content={t("results.meta")}></meta>
            </Helmet>

            {isInitialLoad && isLoading ? (
                <Header>
                    <div>
                        <FontAwesomeIcon icon="circle-notch" spin size="4x" /> <p>{t("results.loadingWelcome")}</p>
                    </div>
                </Header>
            ) : (
                <Wrapper>
                    <Searcher fluid>
                        <Row className="gy-2" style={{ margin: "3px 0px" }}>
                            <Col xs={12} md={3}>
                                <Form.Group>
                                    <Form.Control as="select" size="lg" onChange={event => setSelectedRegion(event?.target?.value)} value={selectedRegion} defaultValue="">
                                        <option value="">Region</option>
                                        {regions?.map((result: any) => {
                                            return (
                                                <option key={result.regionId} value={result.regionId}>
                                                    {result.regionName}
                                                </option>
                                            );
                                        })}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={3}>
                                <Form.Group>
                                    <Form.Control as="select" size="lg" onChange={event => setSelectedCity(event?.target?.value)} value={selectedCity} defaultValue="">
                                        <option value="">City</option>
                                        {cities?.map((result: any) => {
                                            return (
                                                <option key={result.cityId} value={result.cityId}>
                                                    {result.cityName}
                                                </option>
                                            );
                                        })}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="gy-2" style={{ margin: "3px 0px" }}>
                            <Col xs={12} md={3}>
                                <DatePicker
                                    isClearable
                                    showPopperArrow={false}
                                    dateFormat="dd/MM/yyyy"
                                    selected={dateFromString(from)}
                                    onChange={date => setFrom(dateToString(date))}
                                    selectsStart
                                    minDate={dateFromString(moment())}
                                    startDate={dateFromString(from)}
                                    endDate={dateFromString(to)}
                                    customInput={<DateInput label={t("results.checkIn")} />}
                                />
                            </Col>
                            <Col xs={12} md={3}>
                                <DatePicker
                                    isClearable
                                    showPopperArrow={false}
                                    dateFormat="dd/MM/yyyy"
                                    selected={dateFromString(to)}
                                    onChange={date => setTo(dateToString(date))}
                                    selectsEnd
                                    startDate={dateFromString(from)}
                                    endDate={dateFromString(to)}
                                    minDate={dateFromString(from)}
                                    customInput={<DateInput label={t("results.checkOut")} />}
                                />
                            </Col>
                            <Col xs={12} md={3}>
                                <Form.Group>
                                    <Form.Control as="select" size="lg" onChange={event => setNumGuests(event.target.value)} value={guests}>
                                        <option value={1}>1 {t("results.person")}</option>
                                        <option value={2}>2 {t("results.people")}</option>
                                        <option value={3}>3 {t("results.people")}</option>
                                        <option value={4}>4 {t("results.people")}</option>
                                        <option value={5}>5 {t("results.people")}</option>
                                        <option value={6}>6 {t("results.people")}</option>
                                        <option value={7}>7 {t("results.people")}</option>
                                        <option value={8}>8 {t("results.people")}</option>
                                        <option value={9}>9 {t("results.people")}</option>
                                        <option value={10}>10 {t("results.people")}</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={3}>
                                <div className="d-grid gap-2">
                                    <Button variant="primary" size="lg" onClick={refetchProperties}>
                                        {t("results.search")}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Searcher>

                    {!isInitialLoad && isLoading && (
                        <Overlay>
                            <FontAwesomeIcon icon="circle-notch" color="#4e5e9e" spin size="4x" />
                        </Overlay>
                    )}
                    <Results fluid>
                        <Row>
                            {results.length > 0 ? (
                                <>
                                    <ResultsCol xs={12} md={12} lg={12} sm={12} xl={6}>
                                        {results.map((result: any) => {
                                            return (
                                                <StyledLink to={`/view/${result.slug}`} key={result.propertyId}>
                                                    <StyledRow ref={refs[result.propertyId]} className={activeProperty === result.propertyId ? "highlight" : ""}>
                                                        <Col xs={12} md={6}>
                                                            <InfiniteCarousel
                                                                breakpoints={[
                                                                    {
                                                                        breakpoint: 500,
                                                                        settings: {
                                                                            slidesToShow: 2,
                                                                            slidesToScroll: 2
                                                                        }
                                                                    },
                                                                    {
                                                                        breakpoint: 768,
                                                                        settings: {
                                                                            slidesToShow: 3,
                                                                            slidesToScroll: 3
                                                                        }
                                                                    }
                                                                ]}
                                                                arrows={true}
                                                                showSides={true}
                                                                sidesOpacity={0.5}
                                                                sideSize={0}
                                                                slidesToScroll={1}
                                                                slidesToShow={1}
                                                                scrollOnDevice={true}
                                                                lazyLoad={true}
                                                            >
                                                                {result.propertyImages.thumb.map((image: string, index: number) => {
                                                                    return (
                                                                        <div key={index}>
                                                                            <img className="rounded" src={image} height="200" width="350" alt={result.propertyName} />
                                                                        </div>
                                                                    );
                                                                })}
                                                            </InfiniteCarousel>
                                                        </Col>

                                                        <Col xs={12} md={6}>
                                                            <Row>
                                                                <Col>
                                                                    <ApartmentName>{result.propertyName.charAt(0) + result.propertyName.slice(1).toLowerCase()}</ApartmentName>
                                                                    {result.reviews.numReviews > 0 ? (
                                                                        <>
                                                                            <span>{renderStars(result.reviews.averageRating)} </span>
                                                                            <span>
                                                                                {result.reviews.averageRating} ({result.reviews.numReviews})
                                                                            </span>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <span>{t("results.noReviews")}</span>
                                                                        </>
                                                                    )}
                                                                    <br />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} md={6}>
                                                                    <FontAwesomeIcon icon="users" /> {result.propertySpecs.maxCapacity} {t("results.guests")}
                                                                    <br />
                                                                    <FontAwesomeIcon icon="bed" /> {result.propertySpecs.numBedrooms} {t("results.bedrooms")}
                                                                </Col>
                                                                <Col xs={12} md={6}>
                                                                    <FontAwesomeIcon icon="bath" /> {result.propertySpecs.numBathrooms} {t("results.bathrooms")}
                                                                    <br />
                                                                    <FontAwesomeIcon icon="wifi" /> Wi-Fi
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={12}>
                                                                    {result.prices.total ? (
                                                                        <TotalPrice>{t("results.totalPrice", { price: result.prices.total.toLocaleString() })}</TotalPrice>
                                                                    ) : (
                                                                        <StartingPrice>{t("results.startingPrice", { price: result.startingPrice.toLocaleString() })}</StartingPrice>
                                                                    )}
                                                                </Col>
                                                            </Row>
                                                            <BookRow>
                                                                <Col md={12}>
                                                                    <ViewButton>{t("results.view")}</ViewButton>
                                                                </Col>
                                                            </BookRow>
                                                        </Col>
                                                    </StyledRow>
                                                </StyledLink>
                                            );
                                        })}
                                    </ResultsCol>

                                    <MapColumn xs={12} md={6}>
                                        <div
                                            style={{
                                                width: "100%",
                                                height: "100%"
                                            }}
                                        >
                                            <GoogleMapReact
                                                bootstrapURLKeys={{
                                                    key: "AIzaSyA-cHXDmx3_ygpIKSRPmZ4g4ajL6Uy6eV8"
                                                }}
                                                center={{
                                                    lat: parseFloat("41.3910969"),
                                                    lng: parseFloat("2.1548568")
                                                }}
                                                defaultZoom={14}
                                            >
                                                {results.map((result: any) => {
                                                    return (
                                                        <Map
                                                            key={result.propertyId}
                                                            active={activeProperty}
                                                            handleClick={handleClick}
                                                            property={result.propertyId}
                                                            lat={result.propertyLocation.propertyLat}
                                                            lng={result.propertyLocation.propertyLong}
                                                        />
                                                    );
                                                })}
                                            </GoogleMapReact>
                                        </div>
                                    </MapColumn>
                                </>
                            ) : (
                                <Col xs={12} md={6}>
                                    {t("results.noResults")}
                                </Col>
                            )}
                        </Row>
                    </Results>
                </Wrapper>
            )}
        </div>
    );
};

export default ResultsList;
