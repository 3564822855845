import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Alert } from "react-bootstrap";

const Success = styled.div`
    margin: 75px 15px;
`;

const SuccessPage = () => {
    const { t } = useTranslation();

    return (
        <Success>
            <Alert variant="success">
                <Alert.Heading>{t("misc.success")}</Alert.Heading>
                <p>{t("book.bookingSuccess")}</p>
            </Alert>
        </Success>
    );
};

export default SuccessPage;
