import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Alert } from "react-bootstrap";

const Error = styled.div`
    margin: 75px 15px;
`;

const ErrorPage = () => {
    const { t } = useTranslation();

    return (
        <Error>
            <Alert variant="danger">
                <Alert.Heading>{t("misc.error")}</Alert.Heading>
                <p>{t("book.bookingError")}</p>
            </Alert>
        </Error>
    );
};

export default ErrorPage;
