import moment, { Moment } from "moment";

function dateFromString(date: Moment) {
    return date ? moment(date, "YYYY-MM-DD").toDate() : null;
}

function dateToString(date: any) {
    return date ? moment(date).format("YYYY-MM-DD") : null;
}

export { dateToString, dateFromString };
