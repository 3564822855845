import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form as FinalForm, Field } from "react-final-form";
import styled from "styled-components";
import ReactSelect from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, Button, Form, Alert, Breadcrumb } from "react-bootstrap";
import moment from "moment";
import queryString from "query-string";
import "react-datepicker/dist/react-datepicker.css";

//utils
import fetcher from "../utils/fetcher";
import mediaQuery from "../utils/mediaQuery";

//context
import { useBooking } from "../context/BookingContext";

const Wrapper = styled.div`
    margin-top: 65px;
    margin-bottom: 15px;
`;

const StyledContainer = styled(Container)`
    margin-bottom: 50px;
`;

const BookButton = styled(Button)`
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    border-radius: 0;
    height: 50px;
`;

const Overlay = styled.div`
    background: #ffffffe6;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 5000;
    top: 0;
    left: 0;
    float: left;
    text-align: center;
    padding-top: 42.5vh;
    ${mediaQuery.down.md`
        padding-top: 40vh;
    `}
`;

const Error = styled.div`
    margin: 75px 15px;
`;

const renderTextInput = ({ input, meta, label, placeholder }: any) => {
    return (
        <Form.Group controlId={input.name}>
            <Form.Label>{label}</Form.Label>
            <Form.Control {...input} placeholder={placeholder} isInvalid={meta.invalid && meta.modified} isValid={!meta.invalid && meta.modified} />
        </Form.Group>
    );
};

const PromoInput = ({ input, label, placeholder, currentSearch, propertyId }: any) => {
    const [valid, setValid] = useState(false);
    const [invalid, setInvalid] = useState(false);

    useEffect(() => {
        async function fetchData() {
            setValid(false);
            setInvalid(false);

            const promo = await fetcher(`booking/validate-promo`, {
                method: "POST",
                body: JSON.stringify({ ...currentSearch, propertyId, promoCode: input.value })
            });

            if (promo.promoValid) {
                setValid(true);
                setInvalid(false);
            } else {
                setValid(false);
                setInvalid(true);
            }
        }

        if (input.value) {
            fetchData();
        }
    }, [input.value, currentSearch, propertyId]);

    return (
        <Form.Group controlId={input.name}>
            <Form.Label>{label}</Form.Label>
            <Form.Control {...input} placeholder={placeholder} isInvalid={invalid} isValid={valid} />
        </Form.Group>
    );
};

const renderCheckbox = ({ input, meta, label }: any) => {
    return (
        <Form.Group controlId={input.name}>
            <Form.Check
                style={{ zIndex: 0 }}
                type="checkbox"
                checked={input.value}
                onChange={input.onChange}
                label={label}
                isInvalid={meta.invalid && meta.modified}
                isValid={!meta.invalid && meta.modified}
            />
        </Form.Group>
    );
};

const renderSelect = ({ input, label, options, placeholder }: any) => (
    <Form.Group controlId={input.name}>
        <Form.Label>{label}</Form.Label>
        <ReactSelect
            placeholder={placeholder}
            defaultValue={
                input.value && {
                    value: input.value,
                    label: options.find((o: any) => o.value === input.value).label
                }
            }
            isClearable
            onChange={value => input.onChange(value ? value.value : null)}
            theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary25: "#7c89be",
                    primary50: "#6f7eb8",
                    primary75: "#6272b1",
                    primary: "#4e5e9e"
                }
            })}
            options={options}
        />
    </Form.Group>
);

const Book = () => {
    const { t } = useTranslation();
    const { id: slug } = useParams<any>();

    const id = slug.split("-").pop();

    const { currentSearch } = useBooking();

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);

    const [countriesList, setCountriesList] = useState<any[]>([]);
    const [booking, setBooking] = useState<any>({});
    const [payment, setPayment] = useState<any>({
        urlTPV: "",
        signatureVersion: "",
        merchantParams: "",
        signature: ""
    });

    const formRef = useRef<HTMLFormElement | null>(null);

    const required = (value: any) => (value ? undefined : t("book.required"));

    useEffect(() => {
        async function fetchData() {
            const countries = await fetcher(`booking/countries`);

            setCountriesList(countries.data);

            try {
                const booking = await fetcher(`booking/view/${id}?${queryString.stringify(currentSearch)}`);

                setBooking(booking);
            } catch (e) {
                console.log(e);
            }

            setIsLoading(false);
        }

        fetchData();
    }, [id, currentSearch]);

    useEffect(() => {
        if (payment.urlTPV && formRef && formRef.current) {
            formRef.current.submit();
        }
    }, [payment, formRef]);

    const makeBooking = async (values: any) => {
        try {
            const result = await fetcher("booking/new", {
                method: "POST",
                body: JSON.stringify({
                    isNewEngine: 1,
                    propertyId: id,
                    ...currentSearch,
                    ...values
                })
            });

            //submit form
            setPayment(result.payment);
        } catch (e) {
            console.log(e);
            setError(true);
        }
    };

    if (error) {
        return (
            <Error>
                <Alert variant="danger">
                    <Alert.Heading>{t("misc.error")}</Alert.Heading>
                    <p>{t("misc.serverError")}</p>
                </Alert>
            </Error>
        );
    }

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Stay U-nique |{" "}
                    {isLoading
                        ? t("misc.loading")
                        : t("book.title", {
                              apartment: booking.propertyName
                          })}
                </title>
                {!isLoading && (
                    <meta
                        name="description"
                        content={t("book.meta", {
                            apartment: booking.propertyName
                        })}
                    ></meta>
                )}
            </Helmet>
            {!isLoading ? (
                <Wrapper>
                    <StyledContainer fluid>
                        <Breadcrumb>
                            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
                                {t("home.title")}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/results" }}>
                                {t("results.title")}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/view/${id}` }}>
                                {booking.propertyName}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>{t("book.title")}</Breadcrumb.Item>
                        </Breadcrumb>

                        <Row>
                            <Col md={{ span: 10, offset: 1 }}>
                                <h2 className="text-center">{booking.propertyName}</h2>
                            </Col>
                        </Row>

                        <Alert show={!booking.available} variant="danger">
                            <Alert.Heading>{t("misc.error")}</Alert.Heading>
                            <p>{t("book.apartmentNotAvailable")}</p>
                        </Alert>

                        <Alert show={booking.available} variant="info">
                            <Alert.Heading>{t("book.yourApartment")}</Alert.Heading>
                            <ul>
                                <li>
                                    {t("book.checkIn")}: {moment(currentSearch.from).format("DD/MM/YYYY")}
                                </li>
                                <li>
                                    {t("book.checkOut")}: {moment(currentSearch.to).format("DD/MM/YYYY")}
                                </li>
                                <li>
                                    {t("book.numGuests")}: {currentSearch.guests}
                                </li>
                                <li>
                                    {t("book.apartmentName")}: {booking.propertyName}
                                </li>
                                <li>
                                    {t("book.totalPrice")}: €{booking.prices.total} (€{booking.propertyDownpayment} {t("book.payableNow")})
                                </li>
                                <li>
                                    {t("book.cleaningFee")}: €{booking.prices.cleaningFee}
                                </li>
                            </ul>
                        </Alert>
                        <form ref={formRef} action={payment.urlTPV} method="post">
                            <input type="hidden" name="Ds_SignatureVersion" value={payment.signatureVersion} />
                            <input type="hidden" name="Ds_MerchantParameters" value={payment.merchantParams} />
                            <input type="hidden" name="Ds_Signature" value={payment.signature} />
                        </form>

                        <FinalForm
                            onSubmit={makeBooking}
                            render={({ handleSubmit, submitting, invalid }) => (
                                <form onSubmit={handleSubmit}>
                                    <fieldset disabled={booking.available === 0}>
                                        <Row>
                                            <Col md={6}>
                                                <h2>{t("book.yourDetails")}</h2>

                                                <Field name="firstName" component={renderTextInput} validate={required} label={t("book.firstName")} placeholder={t("book.firstNamePlaceholder")} />
                                                <Field name="lastName" component={renderTextInput} validate={required} label={t("book.lastName")} placeholder={t("book.lastNamePlaceholder")} />
                                                <Field
                                                    name="customerEmail"
                                                    component={renderTextInput}
                                                    validate={required}
                                                    label={t("book.customerEmail")}
                                                    placeholder={t("book.customerEmailPlaceholder")}
                                                />
                                                <Field
                                                    name="customerPhone"
                                                    component={renderTextInput}
                                                    validate={required}
                                                    label={t("book.customerPhone")}
                                                    placeholder={t("book.customerPhonePlaceholder")}
                                                />
                                                <Field
                                                    name="customerCountry"
                                                    options={countriesList.map(option => ({
                                                        value: option.countryId,
                                                        label: option.countryName
                                                    }))}
                                                    validate={required}
                                                    component={renderSelect}
                                                    label={t("book.customerCountry")}
                                                    placeholder={t("book.customerCountryPlaceholder")}
                                                />
                                            </Col>

                                            <Col md={6}>
                                                <h2>{t("book.promoCode")}</h2>
                                                <Field name="promoCode" label={t("book.promoCode")} placeholder={t("book.promoCodePlaceholder")}>
                                                    {props => <PromoInput propertyId={id} currentSearch={currentSearch} {...props} />}
                                                </Field>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6}>
                                                <Field name="confirmNotYoungGroup" type="checkbox" component={renderCheckbox} validate={required} label={t("book.youngGroup")} />

                                                <Field name="allowContact" type="checkbox" component={renderCheckbox} label={t("book.allowContact")} />
                                            </Col>
                                        </Row>
                                        <BookButton type="submit" disabled={submitting || invalid}>
                                            {t("book.completePayment")}
                                        </BookButton>
                                    </fieldset>
                                </form>
                            )}
                        />
                    </StyledContainer>
                </Wrapper>
            ) : (
                <Overlay>
                    <FontAwesomeIcon icon="circle-notch" color="#4e5e9e" spin size="4x" />
                </Overlay>
            )}
        </div>
    );
};

export default Book;
