import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, Form, Button, Alert, Breadcrumb } from "react-bootstrap";
import InfiniteCarousel from "react-leaf-carousel";
import DatePicker from "react-datepicker";
import GoogleMapReact from "google-map-react";
import moment from "moment";
import queryString from "query-string";
import "react-datepicker/dist/react-datepicker.css";

//context
import { useBooking } from "../context/BookingContext";

//utils
import fetcher from "../utils/fetcher";
import mediaQuery from "../utils/mediaQuery";
import { dateFromString, dateToString } from "../utils/dateUtils";

//images
import Towel from "../assets/towel.png";
import IroningBoard from "../assets/ironingboard.png";

const Wrapper = styled.div`
    margin-top: 65px;
    margin-bottom: 15px;
`;

const BookButton = styled(Button)`
    display: none;
    ${mediaQuery.down.md`
        background-color: #fbbc04;
        display: block;
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        border-radius: 0;
        height: 50px;
        border: 0px;
    `}
`;

const BookButtonDesktop = styled(Button)`
    ${mediaQuery.down.md`
        display: none;
    `}
    background-color: #fbbc04;
    color: white;
    border: 0px;
`;

const TitleRow = styled(Row)`
    border-radius: 0;
    color: white !important;
    background-color: #4e5e9e !important;
    padding-top: 5px;
    margin-top: 15px;
    &.fixed {
        position: fixed;
        top: 40px;
        width: 100%;
        z-index: 9999;
        height: 50px;
    }
    &.fixed > div > h3 {
        font-size: 1.1rem;
        ${mediaQuery.down.md`
            text-align: center;
        `}
    }
    &.fixed > div > h4 {
        font-size: 1.1rem;
    }
`;

const SearchRow = styled(Row)`
    margin-top: 10px;
    background-color: white !important;
    ${mediaQuery.up.md`
        &.fixed {
            position: fixed;
            top: 90px;
            width: 100%;
            z-index: 9999;
        }
    `}
`;

const MapRow = styled(Row)`
    margin-top: 25px;
`;

const DescriptionRow = styled(Row)`
    margin-top: 25px;
`;

const ReviewsRow = styled(Row)`
    margin-top: 25px;
`;

const AmenitiesRow = styled(Row)`
    margin-top: 25px;
`;

const Overlay = styled.div`
    background: #ffffffe6;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 5000;
    top: 0;
    left: 0;
    float: left;
    text-align: center;
    padding-top: 42.5vh;
    ${mediaQuery.down.md`
        padding-top: 40vh;
    `}
`;

const Image = styled.img`
    object-fit: cover;
`;

const Icon = styled.img`
    margin-left: -12px;
    margin-right: 2px;
    vertical-align: -0.125em;
`;

const Item = styled.span`
    margin-bottom: 5px;
    display: block;
`;

const AmenitiesCol = styled(Col)`
    margin-bottom: 10px;
`;

const LicenceNumber = styled(Col)`
    &.fixed {
        display: none;
    }
`;

const StyledContainer = styled(Container)`
    margin-bottom: 50px;
`;

const BreadcrumbsContainer = styled.div`
    margin: 15px;
`;

const Error = styled.div`
    margin: 75px 15px;
`;

const DateInput = React.forwardRef((props: any, ref: any) => <Form.Control ref={ref} size="lg" type="text" onClick={props.onClick} placeholder={props.label} value={props.value} readOnly />);

const Map = ({ lng, lat }: any) => {
    return <FontAwesomeIcon icon="home" color="#4e5e9e" size="3x" />;
};

function renderStars(amount: number) {
    var stars = [];
    for (var i = 0; i < Math.floor(amount); i++) {
        stars.push(<FontAwesomeIcon color="#fbbc04" icon="star" key={i} />);
    }
    return stars;
}

function ResponsiveImage(props: any) {
    const height = getHeight();

    return <Image style={{ height: height }} {...props} />;
}

function getHeight() {
    if (window.matchMedia("(max-width: 600px)").matches) {
        return "30vh";
    }
    if (window.matchMedia("(max-width: 960px)").matches) {
        return "40vh";
    }
    if (window.matchMedia("(max-width: 1280px)").matches) {
        return "50vh";
    }
    if (window.matchMedia("(max-width: 1920px)").matches) {
        return "70vh";
    } else {
        return "70vh";
    }
}

const View = () => {
    const { t } = useTranslation();
    const { currentSearch, updateSearch } = useBooking();

    const { id: slug } = useParams<any>();

    const id = slug.split("-").pop();

    const [isLoading, setIsLoading] = useState(true);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [property, setProperty] = useState<any>({});
    const [showAffix, setShowAffix] = useState(false);
    const [error, setError] = useState(false);
    const [showMoreReviews, setShowMoreReviews] = useState(false);

    const [from, setFrom] = useState(currentSearch.from);
    const [to, setTo] = useState(currentSearch.to);
    const [guests, setNumGuests] = useState(currentSearch.guests);

    useEffect(() => {
        async function fetchData() {
            try {
                const booking = await fetcher(`booking/view/${id}?${queryString.stringify(currentSearch)}`);

                setProperty(booking);
            } catch (e) {
                console.log(e);
                setError(true);
            }

            setIsInitialLoad(false);
            setIsLoading(false);
        }

        fetchData();
    }, [id, currentSearch]);

    const changeGuests = (guest: string) => {
        setNumGuests(guest);
        updateSearch({ from, to, guests: guest });
    };

    const changeFrom = (date: any) => {
        setFrom(date);
        updateSearch({ from: date, to, guests });
    };

    const changeTo = (date: any) => {
        setTo(date);
        updateSearch({ from, to: date, guests });
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScroll = () => {
        let scroll = window.pageYOffset;
        if (scroll > 280) {
            setShowAffix(true);
        } else {
            setShowAffix(false);
        }
    };

    if (error) {
        return (
            <Error>
                <Alert variant="danger">
                    <Alert.Heading>{t("misc.error")}</Alert.Heading>
                    <p>{t("misc.serverError")}</p>
                </Alert>
            </Error>
        );
    }

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Stay U-nique |{" "}
                    {isLoading
                        ? t("misc.loading")
                        : t("view.title", {
                              apartment: property.propertyName
                          })}
                </title>
                {!isLoading && (
                    <meta
                        name="description"
                        content={t("view.meta", {
                            apartment: property.propertyName
                        })}
                    ></meta>
                )}
            </Helmet>
            {!isInitialLoad ? (
                <Wrapper>
                    <BreadcrumbsContainer>
                        <Breadcrumb>
                            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
                                {t("home.title")}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/results" }}>
                                {t("results.title")}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>{property.propertyName}</Breadcrumb.Item>
                        </Breadcrumb>
                    </BreadcrumbsContainer>
                    <InfiniteCarousel
                        breakpoints={[
                            {
                                breakpoint: 500,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 2
                                }
                            },
                            {
                                breakpoint: 768,
                                settings: {
                                    slidesToShow: 3,
                                    slidesToScroll: 3
                                }
                            }
                        ]}
                        dots={true}
                        showSides={true}
                        sidesOpacity={0.5}
                        sideSize={0}
                        slidesToScroll={1}
                        slidesToShow={1}
                        scrollOnDevice={true}
                        lazyLoad={true}
                    >
                        {property.propertyImages.full.map((image: string, index: number) => {
                            return <ResponsiveImage className="rounded" src={image} key={index} />;
                        })}
                    </InfiniteCarousel>
                    <StyledContainer fluid>
                        <TitleRow className={showAffix ? "fixed" : ""}>
                            <Col md={6}>
                                <h3>{property.propertyName.charAt(0) + property.propertyName.slice(1).toLowerCase()}</h3>
                            </Col>
                            <LicenceNumber md={{ span: 3, offset: 3 }} className={showAffix ? "fixed" : ""}>
                                <h4>
                                    {property.licenseNumber} - SU{property.propertyId}
                                </h4>
                            </LicenceNumber>
                        </TitleRow>

                        <SearchRow className={showAffix ? "fixed gy-2" : "gy-2"}>
                            <Col md={3}>
                                <DatePicker
                                    isClearable
                                    showPopperArrow={false}
                                    dateFormat="dd/MM/yyyy"
                                    selected={dateFromString(from)}
                                    onChange={date => changeFrom(dateToString(date))}
                                    selectsStart
                                    minDate={dateFromString(moment())}
                                    startDate={dateFromString(from)}
                                    endDate={dateFromString(to)}
                                    excludeDates={property.blockedToCheckIn?.map((day: string) => new Date(day))}
                                    customInput={<DateInput label={t("view.checkIn")} />}
                                />
                            </Col>
                            <Col md={3}>
                                <DatePicker
                                    isClearable
                                    showPopperArrow={false}
                                    dateFormat="dd/MM/yyyy"
                                    selected={dateFromString(to)}
                                    onChange={date => changeTo(dateToString(date))}
                                    selectsEnd
                                    startDate={dateFromString(from)}
                                    endDate={dateFromString(to)}
                                    minDate={dateFromString(from)}
                                    excludeDates={property.blockedToCheckOut?.map((day: string) => new Date(day))}
                                    customInput={<DateInput label={t("view.checkOut")} />}
                                />
                            </Col>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Control as="select" size="lg" onChange={event => changeGuests(event.target.value)} value={guests}>
                                        <option value={1}>1 {t("view.person")}</option>
                                        <option value={2}>2 {t("view.people")}</option>
                                        <option value={3}>3 {t("view.people")}</option>
                                        <option value={4}>4 {t("view.people")}</option>
                                        <option value={5}>5 {t("view.people")}</option>
                                        <option value={6}>6 {t("view.people")}</option>
                                        <option value={7}>7 {t("view.people")}</option>
                                        <option value={8}>8 {t("view.people")}</option>
                                        <option value={9}>9 {t("view.people")}</option>
                                        <option value={10}>10 {t("view.people")}</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>

                            <Col md={3}>
                                <Link to={`/book/${slug}`} style={{ textDecoration: "none" }}>
                                    <div className="d-grid">
                                        <BookButtonDesktop variant="secondary" size="lg" disabled={!property.available}>
                                            {property.available
                                                ? t("view.book", { price: property.prices.total.toLocaleString() })
                                                : `${property.unavailableReason ? property.unavailableReason : t("view.noDates")}`}
                                        </BookButtonDesktop>
                                    </div>
                                </Link>
                            </Col>
                        </SearchRow>

                        <DescriptionRow>
                            <Col md={{ span: 10, offset: 1 }}>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: property.propertyShortDescription
                                    }}
                                ></p>

                                <h2 className="text-center">{t("view.specifications")}</h2>

                                <Row className="text-center">
                                    <Col md={3}>
                                        <FontAwesomeIcon color="#4e5e9e" icon="users" /> {property.propertySpecs.maxCapacity} {t("view.capacity")}
                                    </Col>
                                    <Col md={3}>
                                        <FontAwesomeIcon color="#4e5e9e" icon="bed" /> {property.propertySpecs.numBedrooms} {t("view.bedrooms")}
                                    </Col>

                                    <Col md={3}>
                                        <FontAwesomeIcon color="#4e5e9e" icon="shower" /> {property.propertySpecs.numBathrooms} {t("view.bathrooms")}
                                    </Col>

                                    <Col md={3}>
                                        <FontAwesomeIcon color="#4e5e9e" icon="arrows-alt-h" /> {property.propertySpecs.sizeMetersSquared} {t("view.size")}
                                    </Col>
                                </Row>

                                <Row className="text-center">
                                    <Col md={3}>
                                        <FontAwesomeIcon color="#53c3e6" icon="smoking-ban" /> {t("view.noSmoking")}
                                    </Col>
                                    <Col md={3}>
                                        <Icon src={IroningBoard} height="15" width="15" alt={t("view.towels")} /> {t("view.ironing")}
                                    </Col>

                                    <Col md={3}>
                                        <Icon src={Towel} height="15" width="15" alt={t("view.towels")} /> {t("view.towels")}
                                    </Col>
                                    <Col md={3}>
                                        <FontAwesomeIcon color="#53c3e6" icon="wifi" /> {t("view.wifi")}
                                    </Col>
                                </Row>
                            </Col>
                        </DescriptionRow>

                        <AmenitiesRow>
                            <Col md={{ span: 10, offset: 1 }}>
                                <h2 className="text-center">{t("view.amenities")}</h2>

                                <Row className="text-center">
                                    <AmenitiesCol md={3} xs={12}>
                                        <h5 className="text-center">{t("view.kitchen")}</h5>

                                        {property.amenities.microwave === 1 && <Item>{t("view.microwave")}</Item>}
                                        {property.amenities.fridge === 1 && <Item>{t("view.fridge")}</Item>}
                                        {property.amenities.oven === 1 && <Item>{t("view.oven")}</Item>}
                                        <Item>{t("view.coffeeMachine")}</Item>
                                        <Item>{t("view.kitchenWares")}</Item>
                                    </AmenitiesCol>
                                    <AmenitiesCol md={3} xs={12}>
                                        <h5 className="text-center">{t("view.bathroom")}</h5>
                                        <Item>{t("view.hairdryer")}</Item>
                                    </AmenitiesCol>
                                    <AmenitiesCol md={3} xs={12}>
                                        <h5 className="text-center">{t("view.livingRoom")}</h5>
                                        {property.amenities.satTv === 1 && <Item>{t("view.satTv")}</Item>}
                                    </AmenitiesCol>
                                    <AmenitiesCol md={3} xs={12}>
                                        <h5 className="text-center">{t("view.general")}</h5>
                                        {property.amenities.washingMachine === 1 && <Item>{t("view.washingMachine")}</Item>}
                                        {property.amenities.balcony === 1 && <Item>{t("view.balcony")}</Item>}
                                        {property.amenities.terrace === 1 && <Item>{t("view.terrace")}</Item>}
                                        {property.amenities.airConditioning === 1 && <Item>{t("view.airConditioning")}</Item>}
                                        {property.amenities.lift === 1 && <Item>{t("view.lift")}</Item>}
                                        {property.amenities.parking === 1 && <Item>{t("view.parking")}</Item>}
                                    </AmenitiesCol>
                                </Row>
                            </Col>
                        </AmenitiesRow>

                        <MapRow>
                            <Col md={12}>
                                <h2 className="text-center">{t("view.location")}</h2>

                                <div
                                    style={{
                                        height: 300
                                    }}
                                >
                                    <GoogleMapReact
                                        bootstrapURLKeys={{
                                            key: "AIzaSyA-cHXDmx3_ygpIKSRPmZ4g4ajL6Uy6eV8"
                                        }}
                                        center={{
                                            lat: parseFloat(property.propertyLocation.propertyLat),
                                            lng: parseFloat(property.propertyLocation.propertyLong)
                                        }}
                                        defaultZoom={16}
                                    >
                                        <Map lat={property.propertyLocation.propertyLat} lng={property.propertyLocation.propertyLong} />
                                    </GoogleMapReact>
                                </div>
                            </Col>
                        </MapRow>

                        {property.reviews.reviews.length ? (
                            <ReviewsRow>
                                <Col md={{ span: 10, offset: 1 }}>
                                    <h2 className="text-center">{t("view.reviews")}</h2>

                                    <h3>
                                        {property.reviews.reviews.length} {t("view.totalReviews")}
                                    </h3>
                                    {property.reviews.reviews.slice(0, showMoreReviews ? property.reviews.reviews.length : 10).map((review: any, index: number) => {
                                        return (
                                            <div key={index}>
                                                <p>
                                                    {renderStars(review.rating)} <b>{review.customerName}</b> {moment(review.reviewDate, "YYYY-MM-DD HH:mm").format("MMM YY")}
                                                </p>
                                                <p>{review.reviewComment}</p>
                                            </div>
                                        );
                                    })}

                                    {!showMoreReviews && <Button onClick={() => setShowMoreReviews(true)}>{t("view.showMoreReviews")}</Button>}
                                </Col>
                            </ReviewsRow>
                        ) : (
                            <ReviewsRow>
                                <Col md={{ span: 10, offset: 1 }}>
                                    <h2 className="text-center">{t("view.reviews")}</h2>
                                    <h4 className="text-center">{t("view.noReviews")}</h4>
                                </Col>
                            </ReviewsRow>
                        )}

                        <br />

                        <Link to={`/book/${slug}`} style={{ textDecoration: "none" }}>
                            <div className="d-grid">
                                <BookButton variant="primary" size="lg" disabled={!property.available}>
                                    {property.available ? (
                                        t("view.book", { price: property.prices.total.toLocaleString() })
                                    ) : (
                                        <>{property.unavailableReason ? property.unavailableReason : t("view.noDates")}</>
                                    )}
                                </BookButton>
                            </div>
                        </Link>
                    </StyledContainer>
                </Wrapper>
            ) : (
                <Overlay>
                    <FontAwesomeIcon icon="circle-notch" color="#4e5e9e" spin size="4x" />
                </Overlay>
            )}
        </div>
    );
};

export default View;
