import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";

//assets
import Logo from "../Logo";

const NavItem = styled(Nav.Link)`
    color: white;
`;

const CustomNav = styled(Navbar)`
    ${({ hide }) => (hide ? `background-color: transparent` : `background-color: #4E5E9E`)};
`;

export const languages = [
    {
        code: "en",
        icon: "🇬🇧",
        name: "English"
    },
    {
        code: "es",
        icon: "🇪🇸",
        name: "Español"
    },
    {
        code: "fr",
        icon: "🇫🇷",
        name: "Français"
    }
];

type NavigationType = {
    hide: boolean | undefined;
};

const Navigation = ({ hide }: NavigationType) => {
    const { t, i18n } = useTranslation();

    const userLanguage = i18n.language;
    const userLanguageIcon = languages.find((arr: any) => arr.code === userLanguage)?.icon;

    const changeLanguage = (lang: string) => {
        i18n.changeLanguage(lang);
        localStorage.setItem("lang", lang);
    };

    return (
        <CustomNav expand="lg" fixed="top" variant="dark" hide={hide}>
            <Container fluid>
                <Navbar.Brand as={Link} to="/">
                    <Logo />
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <Nav>
                        <NavItem href="https://stay-u-nique.com/en/faq-book-with-us" target="_blank">
                            {t("nav.faq")}
                        </NavItem>
                        <NavItem href="https://stay-u-nique.com/en/about-us" target="_blank">
                            {t("nav.aboutUs")}
                        </NavItem>
                        <NavDropdown
                            title={
                                <span role="img" aria-label={t("nav.currentLang")}>
                                    {userLanguageIcon}
                                </span>
                            }
                            align="end"
                            id="basic-nav-dropdown"
                        >
                            {languages.map(language => (
                                <NavDropdown.Item key={language.code} active={userLanguage === language.code} onClick={() => changeLanguage(language.code)}>
                                    <span role="img" aria-label={language.name}>
                                        {language.icon} {language.name}
                                    </span>
                                </NavDropdown.Item>
                            ))}
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </CustomNav>
    );
};

export default Navigation;
