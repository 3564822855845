import React from "react";
import { Route, Switch, BrowserRouter as Router, Redirect } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import "./App.css";

//conpnents
import ResultsList from "./components/ResultsList";
import ViewApartment from "./components/ViewApartment";
import BookApartment from "./components/BookApartment";
import Home from "./components/Home";
import Success from "./components/Success";
import Error from "./components/Error";
import Layout from "./components/layout/Layout";

//context
import { BookingProvider } from "./context/BookingContext";
import { HelmetProvider } from "react-helmet-async";

//config
import "./i18n/config";

//theme
import "./theme.scss";

library.add(fas);

function App() {
    return (
        <BookingProvider>
            <HelmetProvider>
                <Router>
                    <Switch>
                        <Route exact path="/">
                            <Layout hide={true}>
                                <Home />
                            </Layout>
                        </Route>

                        <Route exact path="/results">
                            <Layout>
                                <ResultsList />
                            </Layout>
                        </Route>

                        <Route exact path="/view/:id">
                            <Layout>
                                <ViewApartment />
                            </Layout>
                        </Route>

                        <Route exact path="/book/:id">
                            <Layout>
                                <BookApartment />
                            </Layout>
                        </Route>

                        <Route exact path="/success">
                            <Layout>
                                <Success />
                            </Layout>
                        </Route>

                        <Route exact path="/error">
                            <Layout>
                                <Error />
                            </Layout>
                        </Route>
                        <Redirect to="/results" />
                    </Switch>
                </Router>
            </HelmetProvider>
        </BookingProvider>
    );
}

export default App;
