import React, { useContext, useState, useEffect } from "react";

const BookingContext = React.createContext<any>(undefined);

const BookingProvider = ({ children }: any) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [currentSearch, setCurrentSearch] = useState<any>({ to: null, from: null, guests: 1, selectedRegion: null, selectedCity: null });

    const updateSearch = (search: any) => {
        localStorage.setItem("bookingEngineCurrentSearch", JSON.stringify(search));
        setCurrentSearch((prev: any) => ({ ...prev, ...search }));
    };

    useEffect(() => {
        const search = localStorage.getItem("bookingEngineCurrentSearch");
        if (search) {
            setCurrentSearch(JSON.parse(search));
        }
        setIsLoading(false);
    }, []);

    if (isLoading) {
        return null;
    }

    return (
        <BookingContext.Provider
            value={{
                currentSearch,
                updateSearch
            }}
        >
            {children}
        </BookingContext.Provider>
    );
};

export function useBooking() {
    const auth = useContext(BookingContext);
    if (auth == null) {
        throw new Error("You must call useBooking only when inside a BookingProvider.");
    }
    return auth;
}

const BookingConsumer = BookingContext.Consumer;

export { BookingProvider, BookingContext, BookingConsumer };
