import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    fallbackLng: "en",
    lng: localStorage.getItem("lang") || "en",
    resources: {
        en: {
            translations: require("./locales/en/translations.json")
        },
        es: {
            translations: require("./locales/es/translations.json")
        },
        fr: {
            translations: require("./locales/fr/translations.json")
        }
    },
    ns: ["translations"],
    defaultNS: "translations"
});

i18n.languages = ["en", "es", "fr"];

export default i18n;
