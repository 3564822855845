import React from "react";
import styled from "styled-components";

import Navigation from "./Navigation";

const Container = styled.div``;

type LayoutType = {
    children: any;
    hide?: boolean | undefined;
};

const Layout = ({ children, hide }: LayoutType) => {
    return (
        <Container>
            <Navigation hide={hide} />
            {children}
        </Container>
    );
};

export default Layout;
