import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Container, Col, Form, Row, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

//utils
import { dateFromString, dateToString } from "../utils/dateUtils";
import fetcher from "../utils/fetcher";

//context
import { useBooking } from "../context/BookingContext";

//assets
import WtaLogo from "../assets/wta-logo.png";

const Header = styled.div`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://assets.stay-u-nique.com/website/headers/header${Math.ceil(Math.random() * 3)}.JPG");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
`;

const WTA = styled.div`
    background-image: url(${WtaLogo});
    z-index: 9999;
    height: 28vh;
    width: 10vw;
    left: 50%;
    margin: auto;
    background-size: cover;
    background-position: center;
    @media (max-width: 768px) {
        display: none;
    }
`;

const Heading = styled.h1`
    font-size: 3.5em;
    @media (max-width: 768px) {
        font-size: 2em;
    }
    margin: 0 20px 20px;
`;

const DateInput = React.forwardRef((props: any, ref: any) => <Form.Control ref={ref} size="lg" type="text" onClick={props.onClick} placeholder={props.label} value={props.value} readOnly />);

const Home = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { currentSearch, updateSearch } = useBooking();

    const [from, setFrom] = useState(currentSearch.from);
    const [to, setTo] = useState(currentSearch.to);
    const [guests, setNumGuests] = useState(currentSearch.guests);
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState(currentSearch.selectedCity);
    const [regions, setRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState(currentSearch.selectedRegion);

    useEffect(() => {
        async function fetchData() {
            try {
                const regionsList = await fetcher(`booking/regions`);
                setRegions(regionsList.data);

                const citiesList = await fetcher(`booking/cities?selectedRegion=${selectedRegion}`);
                setCities(citiesList.data);
            } catch (e) {
                console.log(e);
                // setError(true);
            }

            // setIsInitialLoad(false);
            // setIsLoading(false);
        }
        fetchData();
    }, [currentSearch, selectedRegion]);

    const search = () => {
        updateSearch({ from, to, guests, selectedRegion, selectedCity });
        history.push("/results");
    };

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Stay U-nique | {t("home.title")}</title>
                <meta name="description" content={t("home.meta")}></meta>
            </Helmet>

            <Header>
                <div>
                    <WTA />

                    <Heading>{t("home.welcome")}</Heading>
                    <Container>
                        <Row className="gy-2" style={{ margin: "0px 3px" }}>
                            <Col xs={12} md={3}>
                                <Form.Group>
                                    <Form.Control as="select" size="lg" onChange={event => setSelectedRegion(event?.target?.value)} value={selectedRegion} placeholder="Region" defaultValue="">
                                        <option value="">Region</option>
                                        {regions?.map((region: any) => {
                                            return (
                                                <option key={region.regionId} value={region.regionId}>
                                                    {region.regionName}
                                                </option>
                                            );
                                        })}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={3}>
                                <Form.Group>
                                    <Form.Control
                                        disabled={cities.length ? false : true}
                                        as="select"
                                        size="lg"
                                        onChange={event => {
                                            setSelectedCity(event?.target?.value);
                                        }}
                                        value={selectedCity}
                                        defaultValue=""
                                    >
                                        <option value="">City</option>
                                        {cities?.map((city: any) => {
                                            return (
                                                <option key={city.cityId} value={city.cityId}>
                                                    {city.cityName}
                                                </option>
                                            );
                                        })}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="gy-2" style={{ margin: "0px 3px" }}>
                            <Col xs={12} md={3}>
                                <DatePicker
                                    isClearable
                                    showPopperArrow={false}
                                    dateFormat="dd/MM/yyyy"
                                    selected={dateFromString(from)}
                                    onChange={date => setFrom(dateToString(date))}
                                    selectsStart
                                    minDate={dateFromString(moment())}
                                    startDate={dateFromString(from)}
                                    endDate={dateFromString(to)}
                                    customInput={<DateInput label={t("home.checkIn")} />}
                                />
                            </Col>
                            <Col xs={12} md={3}>
                                <DatePicker
                                    isClearable
                                    showPopperArrow={false}
                                    dateFormat="dd/MM/yyyy"
                                    selected={dateFromString(to)}
                                    onChange={date => setTo(dateToString(date))}
                                    selectsEnd
                                    startDate={dateFromString(from)}
                                    endDate={dateFromString(to)}
                                    minDate={dateFromString(from)}
                                    customInput={<DateInput label={t("home.checkOut")} />}
                                />
                            </Col>
                            <Col xs={12} md={3}>
                                <Form.Group>
                                    <Form.Control as="select" size="lg" onChange={event => setNumGuests(event.target.value)} value={guests}>
                                        <option value={1}>1 {t("home.person")}</option>
                                        <option value={2}>2 {t("home.people")}</option>
                                        <option value={3}>3 {t("home.people")}</option>
                                        <option value={4}>4 {t("home.people")}</option>
                                        <option value={5}>5 {t("home.people")}</option>
                                        <option value={6}>6 {t("home.people")}</option>
                                        <option value={7}>7 {t("home.people")}</option>
                                        <option value={8}>8 {t("home.people")}</option>
                                        <option value={9}>9 {t("home.people")}</option>
                                        <option value={10}>10 {t("home.people")}</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={3}>
                                <div className="d-grid">
                                    <Button variant="primary" size="lg" onClick={search}>
                                        {t("home.search")}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Header>
        </div>
    );
};

export default Home;
